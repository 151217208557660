/* App.css or your global stylesheet */

/* Ensure the default state is transparent */
/* Debugging transition */
.animation-exit {
  opacity: 1;
}

.animation-active-exit {
  opacity: 0;
  transition: opacity 200ms;
}

/* When the transition is active, make it visible */
.my-node-enter {
  opacity: 0;
}

.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.App {
  text-align: center;
  /* color: white; */
  /* aspect-ratio: 1 / 0.618; */
  max-height: 100vh;
  max-width: 100vh;
  border-radius: 5px;
}
