html,
.root {
  font-size: 18px;
  line-height: 23px;
}
.article {
  font-family: sans-serif;
  font-size: 18px;
  line-height: 23px;
  margin: auto;
  background-color: #6b758a;
  justify-content: center;
}
.article2 {
  font-family: sans-serif;
  font-size: 18px;
  line-height: 23px;
  margin: auto;
  background-color: #6b758a;
  justify-content: center;
}
h1,
.h1 {
  font-size: 76px;
  line-height: 92px;
  margin-top: 23px;
  margin-bottom: 46px;
  text-align: left;
}
h2,
.h2 {
  font-size: 47px;
  line-height: 69px;
  margin-top: 23px;
  margin-bottom: 23px;
  text-align: left;
}
h3,
.h3 {
  font-size: 29px;
  line-height: 46px;
  margin-top: 23px;
  margin-bottom: 0px;
  text-align: left;
}
h4,
.h4 {
  font-size: 18px;
  line-height: 23px;
  margin-top: 23px;
  margin-bottom: 0px;
  text-align: left;
}
h5,
.h5 {
  font-size: 18px;
  line-height: 23px;
  margin-top: 23px;
  margin-bottom: 0px;
  text-align: left;
}
p,
ul,
ol,
pre,
table,
blockquote {
  margin-top: 0px;
  margin-bottom: 23px;
  text-align: left;
}
ul ul,
ol ol,
ul ol,
ol ul {
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: left;
}

hr,
.hr {
  border: 1px solid;
  margin: -1px 0;
}
a,
b,
i,
strong,
em,
small,
code {
  line-height: 0;
}
sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}

.container {
  position: relative;
  width: fit-content;
}

.anchorButton {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(-20%, -20%);
  background-color: #f2ab1d;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.anchorButton:hover {
  background-color: gold;
  transform: translate(-20%, -20%);
}

.anchorButton:active {
  background-color: gold;
  animation: moveRotate 0.1s linear;
}

.collapseContainer {
  width: 100%;
  max-height: fit-content;
  display: flex;
  justify-content: center;
}

/* .aboutme {
  max-width: 58%;
  color: #fff;
} */

@keyframes moveRotate {
  to {
    transform: translate(-20%, -20%) rotate(90deg);
  }
}
