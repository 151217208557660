body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #161616;
}

.rotating-text {
  font-family: sans-serif;
  font-weight: 600;
  font-size: 36px;
  color: white;
  transform: translateX(-80px);

  p {
    display: inline-flex;
    margin: 0;
    vertical-align: top;

    .word {
      position: absolute;
      display: flex;
      opacity: 0;

      .letter {
        transform-origin: center center 25px;

        &.out {
          transform: rotateY(90deg);
          transition: 0.5s cubic-bezier(0.6, 0.4, 0.7, 0.6);
        }
        &.in {
          transition: 3.5s ease;
        }
        &.behind {
          transform: rotateY(-90deg);
        }
      }
    }
  }
}

/* palette: https://flatuicolors.com/palette/defo */
.alizarin {
  color: #e74c3c;
}

.wisteria {
  color: #8e44ad;
}

.peter-river {
  color: #3498db;
}

.emerald {
  color: #2ecc71;
}

.sun-flower {
  color: #f1c40f;
  transform: translateX(-60px);
}
