/* Fullscreen background */
/* .mybody {
  background: black !important;
  max-width: 100%;
  container: relative;
  transition: all 5s ease;
  line-height: 23px;
  font-size: 18px;
  font-family: sans-serif;
  margin: auto;
} */

.anchorButton {
  position: absolute !important;
  right: 0;
  bottom: 0;
  transform: translate(-450%, -450%);
}

.landing {
  color: white;
  height: 100vh;
  max-width: 100vh;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: "Courier New", Courier, monospace;
}

/* Typing text wrapper */
.textWrapper {
  display: inline-block;
  font-size: 1.5rem;
  text-align: center;
  margin: 0 auto;
  color: white;
}

/* Cursor blinking effect */
.cursor {
  display: inline-flex;
  font-weight: bold;
  font-size: 1.5rem;
  animation: blink 0.7s steps(2, start) infinite;
  width: fit-content;
  vertical-align: baseline;
  background-color: black;
}

/* Typing effect */
@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.typedText {
  text-align: center;
  word-break: break-word;
  width: fit-content;
  max-width: 80%;
  padding: 0 10px;
  white-space: pre-wrap;
  display: contents;
  background-color: black;
}
